require(['jquery', 'matchHeight'], function ($, matchHeight) {
  function handleVoirPlus(idPlus, idMoins, idContenu) {
    var voirPlusLien = $("#" + idPlus);
    var voirMoinsLien = $("#" + idMoins);
    var voirPlusContenu = $("#" + idContenu);
    voirPlusLien.on('click', function (event) {
      event.preventDefault();
      voirPlusContenu.slideToggle('fast');
      $(this).toggleClass('display-none');
      voirMoinsLien.toggleClass('display-none');
    });
    voirMoinsLien.on('click', function (event) {
      event.preventDefault();
      voirPlusContenu.slideToggle('fast');
      $(this).toggleClass('display-none');
      voirPlusLien.toggleClass('display-none');
    });
  }
  var listVoirPlus = [{
    idPlus: 'js-civile-plus',
    idMoins: 'js-civile-moins',
    idContenu: 'js-civile-contenu'
  }, {
    idPlus: 'js-tous-plus',
    idMoins: 'js-tous-moins',
    idContenu: 'js-tous-contenu'
  }];
  listVoirPlus.forEach(function (_ref) {
    var idPlus = _ref.idPlus,
      idMoins = _ref.idMoins,
      idContenu = _ref.idContenu;
    handleVoirPlus(idPlus, idMoins, idContenu);
  });
});